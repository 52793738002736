<template>
  <v-container id="section-to-print">
    <v-row>
      <v-col/>
      <v-col cols="12">
        <div class='text-h2'>
          Production Schedule
        </div>
      </v-col>
      <v-col cols="12" sm="8">
        <v-row v-for='day in filledWorkDays' :key='day.Date'>
          <v-col cols="12">
            <div class='text-h4'>
              {{day.Date}} 
            </div>
            <v-row>
              <v-col cols="12" >
                <div v-for="(product, index) in getDaySummary(day)" :key="index">
                  {{product[0]}}: {{product[1]}}
                </div>
              </v-col>
                <v-expansion-panels style="margin-left: 12px;">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Details:
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="(line, index) in day.lines" :key="index">
                      <div >
                        {{getOrder(line.id)[0]}}
                        {{line.name}}
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                </v-expansion-panels>
                <v-divider/>
            </v-row>
          </v-col>
          <v-row>
            <v-col>
              <br/>
            </v-col>
          </v-row>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import strapiApi from "../mixins/strapiApi";
const sum = arr =>
  arr.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
export default {
  name: "Schedule",
  mixins: [strapiApi],
  data: () => ({
    loading: false,
    loadingText: "",
    noDataText: "",
    orders: [],
    products: [],
    workDays: [],
    filledWorkDays: [],
    finishNames: ["Walnut", "Clear Lacquer", "Unfinished", "Black"],
    productTypeNames: [
      "Storage Cube",
      "45 Cabinet",
      "Stereocab",
      "Browser Cube"
    ],
    background: "grey lighten-5"
  }),
  async mounted() {
    try {
      this.loading = true;
      this.orders = await this.getOrders("closedAt_null=true&_limit=-1");
      this.orders.sort((a, b) => { return a.shopifyJson.created_at > b.shopifyJson.created_at})
      this.products = await this.getProducts("");
      this.workDays = await this.getWorkDays()
      this.loading = false;
      this.fillWorkDays()
    } catch (error) {
      this.loading = false;
      console.error(error);
    }
  },
  computed: {
    hours: {
      get() {
        return this.$store.state.workHours;
      },
      set(val) {
        return this.$store.commit("setWorkHours", val);
      }
    },
    lineItems() {
      const lineItems = this.orders.flatMap(o => {
        return o.shopifyJson.line_items;
      });
      lineItems.map(li => {
        const product = this.products.find(p => p.shopifyId == li.product_id)
        if (product) li.hoursPerUnit = product.hoursPerUnit
        if (li.hoursPerUnit) li.totalHours = li.hoursPerUnit * li.fulfillable_quantity
        return li 
      })
      return lineItems
    },
    expandedLineItems(){
      const expandedLineItems = []
      const lineItems = this.orders.flatMap(o => {
        return o.shopifyJson.line_items;
      });
      lineItems.map(li => {
        const product = this.products.find(p => p.shopifyId == li.product_id)
        if (product) li.hoursPerUnit = product.hoursPerUnit
        if (li.hoursPerUnit) li.totalHours = li.hoursPerUnit * li.fulfillable_quantity
        return li 
      })
      lineItems.forEach(li => {
        for (let i = 0; i < li.fulfillable_quantity; i++){
          expandedLineItems.push(li)
        }
      }) 
      return expandedLineItems
    },
    owedProducts() {
      return Array.from(
        new Set(
          this.lineItems.map(li => {
            return JSON.stringify({
              id: li.product_id,
              name: this.getLineItemVariantName(li)
            });
          })
        )
      );
    },
  },
  methods: {
    getCollectionOwedVariants(collection) {
      return this.owedProducts
        .filter(v => {
          return collection.products.includes(JSON.parse(v).id.toString());
        })
        .filter(v => {
          return this.getVariantOwedTotal(v) > 0;
        });
    },
    getProductOwedTotal(p) {
      const quantities = this.schedule.queue
        .filter(li => {
          return li.product_id == p.id;
        })
        .map(li => li.fulfillable_quantity);
      return sum(quantities);
    },
    getVariantOwedTotal(v) {
      const quantities = this.schedule.queue
        .filter(li => {
          return this.getLineItemVariantName(li) == JSON.parse(v).name;
        })
        .map(li => li.fulfillable_quantity);
      return sum(quantities);
    },
    getCollectionOwedTotal(collection) {
      const quantities = this.schedule.queue
        .filter(li => {
          return collection.products.some(p => {
            return li.product_id == p;
          });
        })
        .map(li => li.fulfillable_quantity);
      return sum(quantities);
    },
    getLineItemVariantName(li) {
      let name;
      let top;
      if (li.variant_title.toLowerCase().includes("divots")) top = "Divots";
      if (li.variant_title.toLowerCase().includes("smooth")) top = "Smooth";
      if (top) name = li.title + " - " + top;
      else name = li.title;
      return name;
    },
    fillWorkDays(){
      let lineItems = this.expandedLineItems.filter(li => li.hoursPerUnit > 0)
      let lIndex = 0
      for (let i = 0; i < this.workDays.length; i++) {
        let wd = this.workDays[i]
        wd.lines = []
        while (wd.Hours > 0){
            wd.lines.push(lineItems[lIndex])
            lIndex += 1
            wd.Hours -= lineItems[lIndex].hoursPerUnit
          }
        this.filledWorkDays.push(wd)
      }
    },
    getOrder(id) {
      return this.orders.filter(order => {
        
            return order.shopifyJson.line_items
              .flatMap(li => li.id)
              .includes(id);
          
      }).map(o => o.shopifyJson.name)
    },
    getDaySummary(day){
      let summary = {};
      day.lines.map(line => {
        if (!summary[this.getLineItemVariantName(line)]) summary[this.getLineItemVariantName(line)] = 0
        summary[this.getLineItemVariantName(line)] += 1
      })
      return Object.entries(summary).sort()
    },
    printSchedule() {}
  }
};
</script>
<style>
.order-detail-td {
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-top-style: none;
  border-width: thin;
}
.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: #f5f5f5;
}
.v-input--selection-controls {
  margin-top: 0px !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
}

@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
  .v-input--selection-controls__input {
    display: none !important;
  }
  .strike {
    text-decoration-line: line-through;
  }
}
</style>
